<template>
    
    <page page="contact" theme="light" type="page">

        <div class="container">
            <div class="section row">
                <div class="col-md-4">
                    <h3>Contactgegevens</h3>
                    <p class="normal">
                        Dorpsstraat 85<br/>
                        4271 AB Dussen<br/>
                        Noord-Brabant
                    </p>
                    <p class="normal">
                        T: +31 (0) 6 - 22 38 89 27<br/>
                        E: info@deoudestoffeerderij.nl
                    </p>
                </div>
                <div class="col-md-4">
                    <h3>Parkeren</h3>
                    <p class="normal">
                        Parkeren kan op eigen terrein, maar als u parkeren lastig vindt, mag u de auto ook op de dijk laten staan of op een parkeervak aan de dijk.
                    </p>
                </div>
                <div class="col-md-4">
                    <h3>Reserveren</h3>
                    <p class="normal">
                        Wilt u graag reserveren? Dan kun u via de onderstaande knop naar de reservatie pagina gaan.
                    </p>
                    <router-link to="/reserveren" class="btn btn-primary btn-icon"><img src="@/assets/img/icons/calendar-check.svg" />Nu reserveren</router-link>
                </div>
            </div>
        </div>

        <div class="image--block pos-right">
            <div class="image--block-content col-lg-6 col-md-4">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47017.90738263642!2d4.912528419955615!3d51.73167524246777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c69aa4a6a68499%3A0x8c7388db4f7ba0d1!2sDorpsstraat+85%2C+4271+AB+Dussen!5e0!3m2!1snl!2snl!4v1542639887543" width="100%" height="100%" frameborder="0" style="border:0;display: block;" allowfullscreen></iframe>
            </div>
            <div class="container">

                <contact-form></contact-form>
               
            </div>
        </div>

    </page>

</template>

<script>
import Page from '../modules/AppPage.vue';
import ContactForm from '../modules/AppContactForm.vue'

export default {
    metaInfo: {
        title: 'Contact',
        meta: [
            { vmid: 'description', name: 'description', content: 'Parkeren kan op eigen terrein, maar als u parkeren lastig vindt, mag u de auto ook op de dijk laten staan of op een parkeervak aan de dijk.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/contact'}
        ]
    },
    components: {
        Page,
        ContactForm
    }
}
</script>
